import React, { useState, useRef, useLayoutEffect } from "react"
import { graphql, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import Seo from "src/utils/Seo"
import Breadcrumbs from "src/utils/Breadcrumbs"
import BlockCta from "src/components/Blocks/BlockCta"
import Fade from "react-reveal/Fade"
import GatsbyLink from "src/utils/GatsbyLink"

const AccordionItem = props => {
  // Set ref to get the height of each accordion
  const [height, setHeight] = useState(0)
  const ref = useRef()

  useLayoutEffect(() => {
    const updateHeight = () => {
      if (!ref.current) return
      const { height } = ref.current.getBoundingClientRect()
      setHeight(height)
    }

    updateHeight()

    window.addEventListener("resize", updateHeight)
    return () => {
      window.removeEventListener("resize", updateHeight)
    }
  })

  // The click function
  const [opened, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!opened)
  }

  if (props.category === props.data.node.data.category) {
    return (
      <Fade distance="20px" bottom>
        <div className="border-b border-gold group faq-item">
          <div
            className="w-full relative py-5 cursor-pointer focus:outline-none"
            role="button"
            tabIndex={0}
            onClick={() => handleClick()}
            onKeyDown={() => handleClick()}
          >
            <h2
              className={`uppercase font-bold text-xs tracking-widest pr-12 group-hover:text-gold duration-500 ${
                opened ? "text-gold" : "text-white"
              }`}
            >
              {props.data.node.data.title.text}
            </h2>
            <div
              className={`plus accordion absolute ${
                opened ? "" : "white"
              } right-1 top-1/2 transform -translate-y-1/2 ${
                opened ? "active" : ""
              }`}
            ></div>
            {/* <i className={`fal ${opened? "fa-minus text-gold" : "fa-plus text-white"} group-hover:text-gold duration-1000 absolute right-3 top-1/2 transform -translate-y-1/2`}></i> */}
          </div>
          <div
            className={`w-full lg:pr-20 overflow-hidden transition-all duration-1000 ease-in-out`}
            style={{ maxHeight: opened ? height : 0 }}
          >
            <div className="pb-3 text-sm text-white" ref={ref}>
              <RichText
                render={props.data.node.data.content.richText}
                serializeHyperlink={GatsbyLink}
              />
            </div>
          </div>
        </div>
      </Fade>
    )
  }
  return ""
}

const ArchiveFAQs = ({ data: { allPrismicFaq } }) => {
  const data = allPrismicFaq

  const crumbs = [
    { label: "Advice", href: "/advice" },
    { label: "FAQs", href: "/advice/faqs", active: true },
  ]

  // Switch tabs when you click
  const [toggled, setToggle] = useState("General FAQs")
  function handleTabClick(props) {
    setToggle(props)
  }

  return (
    <main>
      <Seo title={"FAQs"} />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <section className={`bg-offwhite`}>
        <div className="container text-center pb-12">
          <Fade>
            <h2 className="font-display text-gold rfs:text-6xl">FAQs</h2>
            <div className="w-12 separator mt-5"></div>
            <p className="mt-6 max-w-xl m-auto">
              Browse the sections below to quickly find answers to our most
              frequently asked questions. If your question isn’t answered here,{" "}
              <Link to="/contact/">contact us.</Link>
            </p>
          </Fade>
        </div>
      </section>
      <div className="w-full bg-white py-6">
        <div className="md:container md:py-0 md:px-0 px-6 overflow-x-auto">
          <div className="space-x-4 w-max m-auto">
            <button
              className={`btn font-bold text-xxs whitespace-nowrap ${
                toggled === "General FAQs" ? "active" : ""
              }`}
              onClick={() => handleTabClick("General FAQs")}
            >
              General FAQs
            </button>
            <button
              className={`btn font-bold text-xxs whitespace-nowrap ${
                toggled === "Internal Doors" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Internal Doors")}
            >
              Internal Doors
            </button>
            <button
              className={`btn font-bold text-xxs whitespace-nowrap ${
                toggled === "Front Doors" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Front Doors")}
            >
              Front Doors
            </button>
            <button
              className={`btn font-bold text-xxs whitespace-nowrap ${
                toggled === "Garage Doors" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Garage Doors")}
            >
              Garage Doors
            </button>
          </div>
        </div>
      </div>
      <section className="bg-black">
        <div className="container max-w-6xl">
          <Fade distance="20px" bottom>
            <h3 className="uppercase text-gold tracking-widest text-sm font-bold mb-6">
              {toggled}
            </h3>
          </Fade>
          {data.edges.map((item, index) => {
            return <AccordionItem data={item} key={index} category={toggled} />
          })}
          <Fade distance="20px" bottom>
            <p className="text-center text-sm mt-12 text-white">
              Got a burning question that isn’t answered here?{" "}
              <Link to="/contact/">Contact our team</Link>.
            </p>
          </Fade>
        </div>
      </section>
      <BlockCta />
    </main>
  )
}

export default ArchiveFAQs

export const FAQsQuery = graphql`
  query FAQs {
    allPrismicFaq {
      edges {
        node {
          data {
            category
            content {
              richText
            }
            title {
              text
            }
          }
        }
      }
    }
  }
`
